window.modal_open = function(html){
  $.when($('#modal').slideUp()).done(function() {
    $('#modal').slideUp();
    $("#overlay").show();
    $("#modal").html(html);
    $("#modal").slideDown();
  });
}

//window.modal_open = function(html){
//  $("body").addClass("body-no-scroll");
//  $("#overlay").show();
//  $("#modal").html(html);
//  $("#modal").slideDown();
//}

window.modal_close = function(){
  $.when($('#modal').slideUp()).done(function() {
    $("#main_content").removeClass("body-no-scroll-pager-modal");
    $('#modal').html('');
    $('#overlay').hide();
  });
}

window.toggleParentNotification = function () {
  $(this).toggleClass('open').next('.child-notification-list').slideToggle();
}

