var Trix = require("trix");

/***** デフォルト設定の変更 *****/

/* 要素の囲みをdivからpに変更 */
Trix.config.blockAttributes.default = {
  tagName: "p",
  breakOnReturn: true
}

/* 斜体はiタグ */
Trix.config.textAttributes.italic = {
  tagName: "i"
}

Trix.config.blockAttributes.heading2 = {
  tagName: 'h2'
}

Trix.config.blockAttributes.heading3 = {
  tagName: 'h3'
}

/* 赤字はemタグで追加 */
Trix.config.textAttributes.red = {
  tagName: 'em'
}

/* smallタグを追加 */
Trix.config.textAttributes.small = {
  tagName: 'small'
}

/* insert the button visual in the default toolbar */
addEventListener("trix-initialize", function(event) {
  var buttonRed = '<button type="button" data-trix-attribute="red" class="trix-button"><i class="fa-solid fa-font" style="font-size: 1.25em;line-height: 1.25em;padding: 2px;color: #ff0000;"></i></button>'
  var buttonSmall = '<button type="button" data-trix-attribute="small" class="trix-button"><i class="fa-solid fa-down-left-and-up-right-to-center" style="font-size: 1.25em;line-height: 1.25em;padding: 2px;"></i></button>'
  var buttonH2 = '<button type="button" data-trix-attribute="heading2" class="trix-button"><i class="fa-solid fa-heading" style="font-size: 1.25em;line-height: 1.25em;padding: 2px;"></i></button>'

  var buttonH3 = '<button type="button" data-trix-attribute="heading3" class="trix-button"><i class="fa-solid fa-heading" style="font-size: 0.9em;line-height: 1.25em;padding: 2px;color: #999999;"></i></button>'

  event.target.toolbarElement.querySelector(".trix-button-group").insertAdjacentHTML("beforeend", buttonRed)
  event.target.toolbarElement.querySelector(".trix-button-group").insertAdjacentHTML("beforeend", buttonSmall)
  event.target.toolbarElement.querySelector(".trix-button-group").insertAdjacentHTML("beforeend", buttonH2)
  event.target.toolbarElement.querySelector(".trix-button-group").insertAdjacentHTML("beforeend", buttonH3)

})

